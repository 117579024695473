@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html, body {
  height: 100vh;
  width: 100vw;
}

body {
  margin: 0;  
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  background-image: url('./assets/background.jpg');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: left calc(50% - 1em);
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Vars */
:root { 
  --dark-blue: #180964;
}

/* Utils */

.NoSelect {
  -webkit-user-select: none;
  user-select: none;
}
