.MainPage {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100vh;
    width: 100vw;
}

.MainPageBody {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.MainPageBanner {
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.MainPageBannerTitle {
    font-size: 9em;
    line-height: 0.9em;
}

.MainPageBannerSubtext {
    font-size: 1.4em;
}

.MainPageBannerStartButton {
    font-size: 1.4em;
    margin-top: 4em;
    border: 1px solid #ffffff;
    padding: 0.6em 6em;
    border-radius: 0.5em;
    cursor: pointer;
}

.MainPageBannerStartButton:hover {
    box-shadow: 0 0 1em 5px rgba(256, 256, 256, 0.2);
}

.MainPageBannerStartButton:active {
    background-color: #ffffff;
    color: var(--dark-blue);
}

.MainPageConnectWalletPanel {
    background-color: #ffffff;
    height: 34em;
    border-radius: 1em;
    box-shadow: 0 0 1.5em 0.2em rgba(256, 256, 256, 0.6);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.5em;
}

.MainPageConnectWalletPanelTitle, .MainPageStealPanelTitle {
    font-size: 2.1em;
    line-height: 2em;
    color: var(--dark-blue);
}

.MainPageConnectWalletList {
    margin-top: 1.7em;
    border: 1px solid var(--dark-blue);
    flex: 1;
    width: 100%;
    border-radius: 0.25em;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.WalletOption {
    display: flex;
    padding: 2em;
    cursor: pointer;
    width: 26em;
}

.WalletOption:hover {
    background-color: rgb(24, 9, 100, 0.1);
}

.WalletOption:active {
    background-color: #ffffff;
}

.WalletIcon {
    width: 2em;
}

.WalletOptionTitle {
    margin-left: 1em;
    font-size: 1.5em;
    color: var(--dark-blue);
}

.WalletOptionArrow {
    margin-left: auto;
    color: rgb(24, 9, 100, 0.7);
    justify-content: center;
    align-content: center;
    font-size: 1.5em;
}

.MainPageStealPanel {
    background-color: #ffffff;
    border-radius: 1em;
    box-shadow: 0 0 1.5em 0.2em rgba(256, 256, 256, 0.6);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em 3.5em;
}

.MainPageStealPanelFormWrap {
    margin-top: 1.7em;
    border: 1px solid var(--dark-blue);
    flex: 1;
    border-radius: 0.25em;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 2em;
    padding-top: 1.8em;
}

.StealFormFieldTitle {
    color: var(--dark-blue);
    font-size: 1.2em;
    margin-bottom: 0.5em;
    margin-left: 1em;
}

.StealFormInputField {
    color: var(--dark-blue);
    margin-bottom: 1.8em;
    font-size: 1.2em;
    border-radius: 0.25em;
    padding: 0.5em;
    border: 1px solid var(--dark-blue);
    width: 28em;
}

.DestinationAddressInputFieldWrapper {
    display: flex;
    flex-direction: row;
}

.DestinationAddressInputField {
    width: 24em;
}

.MeButton {
    background-color: #ffffff;
    color: var(--dark-blue);
    width: 3.6em;
    height: 2.6em;
    text-align: center;
    align-content: center;
    border: 1px solid var(--dark-blue);
    border-radius: 0.25em;
    margin-left: 1em;
    cursor: pointer;
}

.StealFormBottom {
    margin-top: 1em;
    display: flex;
    justify-content: center;
}

.StealButton {
    border: 1px solid var(--dark-blue);
    font-size: 1.4em;
    border-radius: 0.25em;
    width: 8em;
    text-align: center;
    padding: 0.5em 2em;
    cursor: pointer;
    color: var(--dark-blue);
}

.Button:active {
    background-color: var(--dark-blue);
    color: #ffffff;
}

.YoinkPriceIndicator {
    color: var(--dark-blue);
    text-align: center;
    margin-top: 1em;
}
