.TopBar {
    color: #fff;
    padding: 2em 2em;
    display: flex;
    flex-direction: row;
    font-weight: bold;
}

.TopBarLogoText {
    font-size: 2em;
    cursor: pointer;
}

.TopBarRight {
    display: flex;
    margin-left: auto;
    padding-right: 2em;
}

.TopBarButton {
    margin-left: 4em;
    font-size: 1.3em;
    line-height: 1.2em;
    text-align: center;
    cursor: pointer;
}

.ToastContainer {
    margin-top: 4em;
}

.Toastify__toast {
    box-shadow: 0 0 1.5em 0.2em rgba(256, 256, 256, 0.6) !important;
    color: var(--dark-blue) !important;
}

.Toastify__progress-bar-theme--light {
    background: #fff !important;
}

.Toastify__progress-bar {
    background-color: var(--dark-blue);
}
